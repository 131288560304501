type HeaderProps = {
  title: string,
  children?: React.ReactNode,
};

export function PageHeader(props : HeaderProps) {
  return (
    <header className="mx-auto md:flex max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="min-w-0 flex-1">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{props.title}</h1>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        {props.children}
      </div>
    </header>
  );
}
